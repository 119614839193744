<template>
  <div>
    <h3 class="mb-4 text-center text-uppercase font-weight-bold">
      Download app
    </h3>
    <div
      class="align-items-center d-flex flex-column flex-sm-row justify-content-center"
    >
      <b-button
        pill
        variant="outline-secondary"
        class="text-uppercase d-flex align-items-center px-4 py-2 font-weight-bold mr-sm-3 mb-sm-0 mb-3"
      >
        <svg viewBox="0 0 22.773 22.773" class="mr-2">
          <path
            d="M15.769 0h.162c.13 1.606-.483 2.806-1.228 3.675-.731.863-1.732 1.7-3.351 1.573-.108-1.583.506-2.694 1.25-3.561C13.292.879 14.557.16 15.769 0zM20.67 16.716v.045c-.455 1.378-1.104 2.559-1.896 3.655-.723.995-1.609 2.334-3.191 2.334-1.367 0-2.275-.879-3.676-.903-1.482-.024-2.297.735-3.652.926h-.462c-.995-.144-1.798-.932-2.383-1.642-1.725-2.098-3.058-4.808-3.306-8.276v-1.019c.105-2.482 1.311-4.5 2.914-5.478.846-.52 2.009-.963 3.304-.765.555.086 1.122.276 1.619.464.471.181 1.06.502 1.618.485.378-.011.754-.208 1.135-.347 1.116-.403 2.21-.865 3.652-.648 1.733.262 2.963 1.032 3.723 2.22-1.466.933-2.625 2.339-2.427 4.74.176 2.181 1.444 3.457 3.028 4.209z"
          />
        </svg>
        Mac OS
      </b-button>
      <b-button
        pill
        variant="outline-secondary"
        class="text-uppercase d-flex align-items-center px-4 py-2 font-weight-bold"
      >
        <svg viewBox="0 0 512.001 512.001" class="mr-2">
          <g fill="#579add">
            <path
              d="M246.984 38.837C337.496 24.059 417.649 12.915 507.881.033c1.957-.28 3.717 1.238 3.717 3.214v236.437a3.242 3.242 0 01-3.24 3.245c-89.633.043-181.009 1.828-270.641 1.871a3.25 3.25 0 01-3.249-3.252V43.499a3.25 3.25 0 012.771-3.213l9.745-1.449zM13.612 70.411L206.45 44.928a3.248 3.248 0 013.674 3.22v193.409a3.247 3.247 0 01-3.247 3.244c-68.903.054-136.075 1.814-203.219 1.87a3.255 3.255 0 01-3.255-3.254V75.3a3.248 3.248 0 012.734-3.207l10.475-1.682zM3.659 267.272c67.117.056 134.262 1.785 203.138.084a3.245 3.245 0 013.328 3.241v195.2a3.254 3.254 0 01-3.679 3.221c-68.903-9.075-136.078-18.148-203.229-27.223a3.249 3.249 0 01-2.813-3.219v-168.05a3.255 3.255 0 013.255-3.254zM246.984 269.144h261.365a3.25 3.25 0 013.249 3.249v236.364c0 2-1.787 3.52-3.761 3.201-89.619-14.525-180.973-25.554-270.579-38.313a3.25 3.25 0 01-2.791-3.22V272.392a3.25 3.25 0 013.249-3.249l9.268.001z"
            />
          </g>
          <g fill="#4987ce">
            <path
              d="M255.039 241.485V39.592a3.3 3.3 0 01.844-2.202c-2.955.478-5.921.96-8.898 1.447l-9.747 1.449a3.249 3.249 0 00-2.771 3.213v198.049a3.25 3.25 0 003.249 3.252c6.683-.003 13.394-.033 20.096-.054a3.307 3.307 0 01-2.773-3.261zM20.975 243.344V71.381c0-.764.268-1.469.709-2.036l-8.072 1.066-10.474 1.68a3.248 3.248 0 00-2.734 3.207v168.118a3.254 3.254 0 003.255 3.254c6.65-.006 13.301-.039 19.953-.075a3.326 3.326 0 01-2.637-3.251zM208.576 267.842a3.234 3.234 0 00-1.78-.486l1.78.486zM20.975 444.019V270.63a3.358 3.358 0 012.714-3.293c-6.678-.034-13.355-.059-20.031-.065a3.254 3.254 0 00-3.255 3.254v168.049a3.248 3.248 0 002.814 3.219l17.776 2.401c-.005-.059-.018-.115-.018-.176zM255.039 474.705V272.461a3.319 3.319 0 013.318-3.318h-20.641a3.248 3.248 0 00-3.248 3.248v198.033a3.251 3.251 0 002.791 3.22c6.042.861 12.094 1.712 18.151 2.558a3.273 3.273 0 01-.371-1.497z"
            />
          </g>
        </svg>
        Windows
      </b-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
  width: 20px;
  height: 20px;
}
</style>
